define("discourse/plugins/discourse-extra-group-classes/discourse/components/extra-group-classes-group-edit", ["exports", "@ember/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse-i18n"], function (_exports, _component, _object, _service, _ajax, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    dialog: (0, _service.inject)(),
    tokenSeparator: "|",
    extraClasses: (0, _object.computed)("group.extra_classes", function () {
      if (this.group.extra_classes) {
        return this.group.extra_classes.split(this.tokenSeparator).filter(Boolean);
      }
      return null;
    }),
    actions: {
      extraGroupClassesChanged(value) {
        let newValue = value.join(this.tokenSeparator);
        let group = this.group;
        let oldValue = this.group.extra_classes;
        group.set("extra_classes", newValue);
        return (0, _ajax.ajax)(`/admin/groups/${group.id}/extra_classes`, {
          type: "PUT",
          data: group.getProperties("extra_classes")
        }).catch(() => {
          group.set("extra_classes", oldValue);
          this.dialog.alert(_discourseI18n.default.t("generic_error_with_reason", {
            error: _discourseI18n.default.t("extra_group_classes.save_error")
          }));
        });
      }
    }
  });
});
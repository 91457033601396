define("discourse/plugins/discourse-extra-group-classes/discourse/templates/connectors/group-edit/extra-group-classes", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{extra-group-classes-group-edit group=group}}
  */
  {
    "id": "OoL3Civj",
    "block": "[[[1,[28,[35,0],null,[[\"group\"],[[30,0,[\"group\"]]]]]],[1,[28,[32,0],[\"[[\\\"The `group` property path was used in the `discourse/plugins/discourse-extra-group-classes/discourse/templates/connectors/group-edit/extra-group-classes.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.group}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"extra-group-classes-group-edit\"]]",
    "moduleName": "discourse/plugins/discourse-extra-group-classes/discourse/templates/connectors/group-edit/extra-group-classes.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});